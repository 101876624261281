define("itarp-opportunity-designer/routes/candidates", ["exports", "itarp-query-filters/utils/schema/user", "itarp-auth-addon/routes/application", "@ember/service", "@ember/object", "rsvp"], function (_exports, _user, _application, _service, _object, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CandidatesRoute = (_dec = (0, _service.inject)(), _dec2 = (0, _service.inject)(), _dec3 = (0, _service.inject)('filters'), _dec4 = (0, _service.inject)('opportunity/authorization'), (_class = class CandidatesRoute extends _application.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "workflows", _descriptor2, this);

      _initializerDefineProperty(this, "filtersService", _descriptor3, this);

      _initializerDefineProperty(this, "authorization", _descriptor4, this);

      _defineProperty(this, "queryParams", { ..._user.default,
        place: {
          refreshModel: false
        },
        status: {
          refreshModel: true
        },
        page: {
          refreshModel: true
        }
      });
    }

    buildRouteInfoMetadata() {
      return {
        title: 'Candidates for Opportunities'
      };
    }

    model(params) {
      const shouldResetPage = this.shouldResetPageParams(params);
      if (shouldResetPage) params.page = 1;
      const filters = this.filtersService.buildQuery(this, params);
      const keywords = this.getKeywords(filters, params);
      const status = params.status;
      const filter = status ? {
        workflow_stage_id: status
      } : undefined;
      return (0, _rsvp.hash)({
        params,
        shouldResetPage,
        stageOptions: this.workflows.allStageOptionsForOpportunity(),
        changeStagePermitted: this.authorization.isPermitted(this.authorization.permissions.candidates.edit.stage),
        applicants: this.store.query('proposal', {
          opportunityCandidates: true,
          per_page: 20,
          page: params.page,
          filters,
          keywords,
          filter
        }).then(data => {
          let meta = data.meta;
          if (meta.meta) meta = meta.meta;
          return {
            data,
            meta
          };
        })
      });
    }

    setupController(controller, model) {
      super.setupController(...arguments);
      const {
        data
      } = model.applicants;
      if (model.shouldResetPage) controller.set('page', 1);
      const meta = model.applicants.meta && model.applicants.meta.page ? model.applicants.meta.page : model.applicants.meta;
      const total = meta && meta.total ? meta.total : 0;
      const hasMultiplePages = total > 20;
      if (meta) controller.set('pageMeta', meta);
      controller.setProperties({
        total: meta.total,
        applicants: data,
        hasMultiplePages,
        filterItems: { ...this.filterItems
        },
        filterChips: this.filterChips,
        filterSchema: this.queryParams,
        updateQueryParams: this.updateQueryParams,
        stageOptions: model.stageOptions,
        changeStagePermitted: model.changeStagePermitted
      });
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('page', 1);
        controller.setProperties({
          application: null,
          tab: null,
          keywords: null,
          contractor: null,
          full_time: null,
          part_time: null,
          work_available: null,
          graduation_start: null,
          graduation_end: null,
          rate_start: null,
          rate_end: null,
          job_title: null,
          experience: null,
          part_time_available: null,
          between_time_available: null,
          full_time_available: null,
          remote: null,
          local_commute_only: null,
          willing_to_travel: null,
          willing_to_relocate: null,
          salary_min: null,
          salary_max: null,
          location: null,
          status: null,
          filterItems: null,
          stageOptions: null,
          changeStagePermitted: null
        });
        this.filterItems = null;
      }
    }

    beforeModel() {
      if (this.filterItems) return;
      const route = this;
      this.filterItems = this.filtersService.setupFilters(route);
    }

    afterModel(model) {
      const chips = this.filtersService.deserializeFilters(model.params, this.filterItems, this);
      this.filterChips = chips;
    }

    updateQueryParams() {
      const route = this;
      this.filtersService.serializeFilters(this.filterItems, route);
    }

    getKeywords(filters, {
      keywords
    }) {
      var _filter$skills;

      const filter = filters.find(f => Object.keys(f).includes('skills'));
      let value = keywords || '';

      if (filter && ((_filter$skills = filter.skills) === null || _filter$skills === void 0 ? void 0 : _filter$skills.length) > 0) {
        filters.removeObject(filter);
        const skills = filter.skills.join(',');
        value = value ? value.concat(',', skills) : skills;
      }

      let result = this.filterItems.keywords.serializeSearch(value);
      return result;
    }

    parametersMatch(params) {
      if (!this.staleParams) return true;
      const currentParams = Object.assign({}, params);
      const asString = JSON.stringify;
      delete currentParams.page;
      delete this.staleParams.page;
      return asString(currentParams) === asString(this.staleParams);
    }

    shouldResetPageParams(params) {
      const shouldReset = this.parametersMatch(params) !== true;
      this.staleParams = Object.assign({}, params);
      return shouldReset;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "workflows", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "filtersService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "authorization", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "beforeModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "beforeModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "afterModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateQueryParams", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateQueryParams"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getKeywords", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getKeywords"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "parametersMatch", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "parametersMatch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "shouldResetPageParams", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "shouldResetPageParams"), _class.prototype)), _class));
  _exports.default = CandidatesRoute;
});