define("itarp-opportunity-designer/utils/permissions/opportunity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PERMISSIONS = _exports.IDENTIFIER = void 0;
  const IDENTIFIER = 'job_opportunity';
  _exports.IDENTIFIER = IDENTIFIER;
  const PERMISSIONS = {
    create: 'create',
    edit: {
      all: 'edit_all',
      own: 'edit',
      allInGroup: 'edit_all_in_group'
    },
    view: {
      all: 'view_all',
      own: 'view',
      draft: 'view_draft',
      pending: 'view_pending',
      allInGroup: 'view_all_in_group'
    },
    action: {
      approve: 'can_approve',
      clone: 'can_clone',
      close: 'can_close',
      hold: 'can_hold',
      publish: 'can_publish',
      setClosingDate: 'can_set_closing_date',
      linkTalentClouds: 'can_link_talent_clouds',
      export: {
        all: 'export_all',
        allInGroup: 'export_all_in_group'
      },
      linkScorecard: 'link_scorecard_opportunity',
      linkApprovalWorkflow: 'link_approval_workflow',
      linkRecruitingWorkflow: 'link_recruiting_workflow'
    },
    collaborators: {
      view: 'access_collaborator'
    },
    candidates: {
      view: {
        sourcing: 'access_sourcing',
        unsuccessful: 'access_unsuccessful'
      },
      edit: {
        stage: 'edit_candidate_application_stage'
      },
      action: {
        invite: 'invite_expert'
      }
    }
  };
  _exports.PERMISSIONS = PERMISSIONS;
  var _default = {
    PERMISSIONS,
    IDENTIFIER
  };
  _exports.default = _default;
});